import * as React from "react";
import Layout from "../components/Layout/Layout";
import parse from "html-react-parser";
import AboutCourse from "../components/academyTumar/AboutCourse";
import Schedule from "../components/academyTumar/Schedule";
import { Link } from "gatsby";
import { injectIntl } from "gatsby-plugin-intl";
import BreadCrumbs from "../BreadCrumbs";
import { useQuery } from "@apollo/client";
import { cmsImageLoader } from "../utils/cmsImageLoader";
import ArrowLeft from "../images/arrow_left.svg";
import { GET_COURSES } from "../query/getCourses";
import AcademyTumarForm from "../components/popups/AcademyTumarForm";
import {useState} from "react";

//@ts-ignore
const academyTumarDetail = ({ intl, pageContext, location }) => {
  const [showSuccessApplyForm, setShowSuccessApplyForm] = useState(false);

  const handleButtonClick = () => {
    setShowSuccessApplyForm(true);
  };
  const params = new URLSearchParams(location.search);
  const id = params.get("id");

  const { data, loading, error } = useQuery(GET_COURSES, {
    variables: {
      locale: intl.locale,
    },
    fetchPolicy: "network-only",
  });

  const dataById = data?.courses?.data.filter(
    (item: any) => item.attributes.sharedId == id
  );

  if (loading) return "";

  if (error) return `Error! ${error.message}`;

  const laptopBannerUrl =
    dataById[0]?.attributes?.bannerLaptop?.data?.attributes?.url;
  const tabletHorizontalBannerUrl =
    dataById[0]?.attributes?.bannerTabletHorizontal?.data?.attributes?.url;
  const tabletVerticalBannerUrl =
    dataById[0]?.attributes?.bannerTabletVertical?.data?.attributes?.url;
  const phoneBannerUrl =
    dataById[0]?.attributes?.bannerPhone?.data?.attributes?.url;

  const getBannerUrl = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 1024) {
      return laptopBannerUrl;
    } else if (windowWidth >= 768 && windowWidth < 1024) {
      return tabletHorizontalBannerUrl;
    } else if (windowWidth >= 480 && windowWidth < 768) {
      return tabletVerticalBannerUrl;
    } else {
      return phoneBannerUrl;
    }
  };

  return (
    <Layout>
      <div className="relative z-0 flex items-center justify-center bg-[#FAFAFA]">
        <div className="mb-[10rem] flex flex-col justify-center xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
          <Link to="/academy-tumar">
            <div className="flex ml-[0.2rem] mt-[2.5rem] mb-[2.5rem] items-center">
              <img src={ArrowLeft} alt="" className="sm:mb-[0rem]" />
              <p className="font-semibold text-[1.25rem] ml-[10px] text-[#001439] cursor-pointer">
                {parse(
                  intl.formatMessage({
                    id: `AcademyTumar.Title`,
                  })
                )}
              </p>
            </div>
          </Link>
          {/*Box*/}
          <div
            className={`text-white bg-[#EDF2FC] bg-opacity-25 flex sm:flex-row xs:flex-col sm:items-center xs:items-start lg:w-auto sm:w-auto sm:h-[16rem] xs:h-[22rem] justify-between text-center  items-center mb-2 overflow-hidden rounded-xl sm:p-4 xs:p-[1rem] px-4 border border-[#EDF2FC] border-2  xs:text-[0.7rem] sm:text-sm lg:text-[0.8rem]`}
            style={{
              backgroundImage: `url(${cmsImageLoader({
                src: getBannerUrl(),
              })})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          {/*Tab*/}
          <div className="h-[2.5rem] mt-[3rem] flex border border-b border-[#618EE4] border-opacity-50 border-t-0 border-x-0">
            <div className="w-[10rem] academyTumar_detail_active  flex justify-center items-center">
              <p className="sm:text-[1.125rem] text-[1rem]">
                {parse(
                  intl.formatMessage({
                    id: `AcademyTumar.Tab.AboutCourse`,
                  })
                )}
              </p>
            </div>
            {/* <div className="w-[11rem] academyTumar_detail_active flex justify-center items-center">
              {parse(
                intl.formatMessage({
                  id: `AcademyTumar.Tab.Schedule`,
                })
              )}
            </div> */}
          </div>
          <AboutCourse body={dataById[0]?.attributes?.body} />
          {/* <Schedule /> */}
            <button className="mt-[2rem] cursor-pointer lg:mt-[2rem] sm:mt-[2.5rem]  flex items-center justify-center w-[9.6rem] h-[2.8rem] bg-[#618EE4] rounded-[0.6rem]"
                    onClick={() => handleButtonClick()}>
              <p className="font-semibold text-[1rem] text-white">
                {parse(
                  intl.formatMessage({
                    id: `AcademyTumar.Apply`,
                  })
                )}
              </p>
            </button>
          {showSuccessApplyForm && <AcademyTumarForm setShowApplyModal={setShowSuccessApplyForm} courseTitle={dataById[0]?.attributes?.title} courseId={dataById[0]?.id}/>}
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(academyTumarDetail);
