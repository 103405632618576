import * as React from "react";

import { injectIntl } from "gatsby-plugin-intl";

import rehypeRaw from "rehype-raw";

import ReactMarkdown from "react-markdown";

// @ts-ignore
const AboutCourse = ({ body }) => {
  return (
    <div className="flex flex-col mt-8">
      <p>
        <ReactMarkdown
          linkTarget="_blank"
          skipHtml={true}
          rehypePlugins={[rehypeRaw as any]}
        >
          {body.replaceAll(
            "/uploads",
            `${process.env.STRAPI_BASE_URL}/uploads`
          )}
        </ReactMarkdown>
      </p>
    </div>
  );
};

export default injectIntl(AboutCourse);
