import { gql } from "@apollo/client";

export const MAKE_COURSE_ORDER = gql`
mutation makeCourseOrder($input: OrderForCourseInput!) {
  createOrderForCourse(data: $input) {
    data {
      id
      attributes {
        name
        surname
        patronymic
        phone
        email
        course {
          data {
            id
          }
        }
      }
    }
  }
}
`;
