import * as React from "react";
import {injectIntl} from "gatsby-plugin-intl";
import CrossButton from "../../images/cross_button.svg";
import parse from "html-react-parser";
import {Field, Form, Formik, FormikHelpers} from "formik";
import {useMutation} from "@apollo/client";
import {MAKE_COURSE_ORDER} from "../../mutation/makeCourseOrder";
import {useState} from "react";
import { AppStateContext } from "../Layout/Layout";
import * as Yup from "yup";
import {phoneNumberMask, phoneNumberRegex} from "../../utils/regex";
import MaskedInput from "react-text-mask";
import ResultSuccess from "./ResultSuccess";
import ResultError from "./ResultError";

interface Values {
  name: string; // Имя
  surname: string; // Фамилия
  patronymic: string; // Отчество
  phone: string; //  +7 XXX XXXXXXX
  email:string;
  course: number | null;
}

// @ts-ignore
const AcademyTumarForm = ({ intl, setShowApplyModal, courseTitle, courseId}) => {
  const [makeCourseOrder, { loading }] = useMutation(MAKE_COURSE_ORDER);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [showResultSuccessPopup, setShowResultSuccessPopup] = useState(false);
  const [showSubmitErrorModal, setShowSubmitErrorModal] = useState(false);

  const handleCheckboxChange = () => {
    setAgreementChecked(!agreementChecked);
  };

  const handleTextClick = () => {
    setAgreementChecked(!agreementChecked);
  };

  const closeModalHandler = () => {
    setShowApplyModal(false);
  };

  const prepareData = (values: Values) => {
    const preparedData = {
      name: values.name,
      surname: values.surname,
      patronymic: values.patronymic,
      phone: values.phone.replace(/\D/g, ""),
      email: values.email,
      course: courseId ? Number(courseId) : null,
    };

    return preparedData;
  };
  const phoneNumberFromDataBase = /7\d{10}/gm;
  const phoneNumberRegexCombined = new RegExp(`${phoneNumberRegex.source}|${phoneNumberFromDataBase.source}`);

  const makeCourseOrderSchema = Yup.object().shape({
    surname: Yup.string().required(
        intl.formatMessage({ id: "Validation.Required" })
    ),

    name: Yup.string().required(
        intl.formatMessage({ id: "Validation.Required" })
    ),

    patronymic: Yup.string(),

    email: Yup.string()
        .required(intl.formatMessage({ id: "Validation.Required" }))
        .email(intl.formatMessage({ id: "Validation.Format.Email" })),

    phone: Yup.string()
        .required(intl.formatMessage({ id: "Validation.Required" }))
        .matches(
            phoneNumberRegexCombined,
            intl.formatMessage({ id: "Validation.Required" })
        ),
  });

  return (
      <>
        <AppStateContext.Consumer>
          {({ profile }) => (
              <Formik
                  initialValues={{
                    surname: (profile && profile.surname) || "",
                    name: (profile && profile.name) || "",
                    patronymic: (profile && profile.patronymic) || "",
                    phone: (profile && profile.username) ? profile.username.replace(/^\+7\s*/, "").replace(/\D/g, "").replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/, "+7 ($1) $2 $3 $4") : "",
                    email: (profile && profile.email) || "",
                    course: courseId ? Number(courseId) : null,
                  }}
                  validationSchema={makeCourseOrderSchema}
                  onSubmit={(
                      values: Values,
                      {setSubmitting}: FormikHelpers<Values>
                  ) => {
                    makeCourseOrder({
                      variables: {
                        input: prepareData(values),
                      },
                      onCompleted: () => {
                        setShowResultSuccessPopup(true);
                        setSubmitting(false);
                      },
                      onError: (error) => {
                        console.error(error.message);
                        setShowSubmitErrorModal(true);
                      },
                    });
                  }}
              >
                {({ errors, touched, handleChange, values, handleBlur }) => (
                    <Form>
                      <header className="flex justify-center items-center mb-[0.4rem]">
                        <div
                            className="flex flex-col items-center lg:max-w-[1200px] lg:w-[1200px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px] ">
                          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 z-40"></div>
                          <div
                              className="flex items-center pb-[3rem] sm:px-[2rem] xs:px-[1rem] flex-col rounded-[1.25rem] lg:w-[45rem] sm:w-[40rem] xs:w-[18.5rem]  h-auto  border fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white z-50">
                            <div className="w-[100%]">
                              {/*title*/}
                              <div className="grid grid-flow-col justify-between mt-[2.6rem]">
                                <h3 className="sm:w-auto  font-medium text-[1.5rem]">
                                  {parse(intl.formatMessage({id: "AcademyTumar.ApplyTitle"}))}
                                </h3>
                                <img src={CrossButton} alt="" className="cursor-pointer" onClick={() => {
                                  closeModalHandler()
                                }}/>
                              </div>
                              <p className="mt-[1.1rem] font-medium text-[1.25rem]">
                                {courseTitle}
                              </p>
                              {/*inputs*/}
                              <div className="grid sm:grid-cols-2 xs:flex-col sm:mt-[2rem] xs:mt-[1rem] gap-6">
                                <div className="grid grid-flow-row ">
                                  <p className="font-medium text-[1rem]">
                                    {parse(intl.formatMessage({id: "Registration.Form.Surname"}))} <span
                                      className="text-red-600">*</span>
                                  </p>
                                  <Field
                                      type="text"
                                      name="surname"
                                      placeholder={parse(
                                          intl.formatMessage({
                                            id: "Registration.Form.Surname",
                                          })
                                      )}
                                      className="pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                                  />
                                  <p className="text-red-600 text-xs h-4">
                                    {errors.surname &&
                                        touched.surname &&
                                        errors.surname}
                                  </p>
                                </div>
                                <div className="grid grid-flow-row ">
                                  <p className="font-medium text-[1rem]">
                                    {parse(intl.formatMessage({id: "Registration.Form.Name"}))} <span
                                      className="text-red-600">*</span>
                                  </p>
                                  <Field
                                      type="text"
                                      name="name"
                                      placeholder={parse(
                                          intl.formatMessage({id: "Registration.Form.Name"})
                                      )}
                                      className="pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                                  />
                                  <p className="text-red-600 text-xs h-4">
                                    {errors.name && touched.name && errors.name}
                                  </p>
                                </div>
                                <div className="grid grid-flow-row ">
                                  <p className="font-medium text-[1rem]">
                                    {parse(intl.formatMessage({id: "Registration.Form.MiddleName"}))}
                                  </p>
                                  <Field
                                      type="text"
                                      name="patronymic"
                                      placeholder={parse(
                                          intl.formatMessage({
                                            id: "Registration.Form.MiddleName",
                                          })
                                      )}
                                      className="pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                                  />
                                  <p className="text-red-600 text-xs h-4">
                                    {errors.patronymic &&
                                        touched.patronymic &&
                                        errors.patronymic}
                                  </p>
                                </div>
                                <div className="grid grid-flow-row ">
                                  <p className="font-medium text-[1rem]">
                                    {parse(intl.formatMessage({id: "Registration.Form.PhoneNumber"}))} <span
                                      className="text-red-600">*</span>
                                  </p>
                                  <Field
                                      name="phone"
                                      render={({field}) => (
                                          <MaskedInput
                                              {...field}
                                              mask={phoneNumberMask}
                                              showMask="true"
                                              id="phone"
                                              type="text"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              className="pl-[1rem] px-[0.8rem] mt-[0.875rem] w-auto h-[3rem] border rounded-[0.5rem]"
                                          />
                                      )}
                                  />
                                  <p className="text-red-600 text-xs h-4">
                                    {errors.phone && touched.phone && errors.phone}
                                  </p>
                                </div>
                                <div className="grid sm:grid-flow-row ">
                                  <p className="font-medium text-[1rem]">
                                    {parse(intl.formatMessage({id: "Registration.Form.Email"}))} <span
                                      className="text-red-600">*</span>
                                  </p>
                                  <Field
                                      type="text"
                                      id="email"
                                      name="email"
                                      placeholder="E-mail"
                                      className="pl-[1rem] px-[0.8rem] mt-[0.875rem] lg:w-[20rem] sm:w-[17rem] xs:w-[16.5rem] h-[3rem] border rounded-[0.5rem]"
                                  />
                                  <p className="text-red-600 text-xs h-4">
                                    {errors.email && touched.email && errors.email}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <p className="text-red-600 text-xs h-4">
                              {errors.form && errors.form}
                            </p>
                            {/*Agreement*/}
                            <div className="flex w-[100%] mt-[2rem] gap-5 items-center">
                              <input
                                  type="checkbox"
                                  className="w-[2rem] h-[2rem] rounded-[0.5rem] border border-[#001439] border-opacity-25"
                                  checked={agreementChecked}
                                  onChange={handleCheckboxChange}
                              />
                              <p className="font-medium text-[1rem] cursor-pointer" onClick={handleTextClick}>
                                {parse(intl.formatMessage({id: "Registration.Form.Agreement"}))}
                              </p>
                            </div>

                            {/*button*/}
                            <button
                                className={`sm:mt-[2rem] xs:mt-[1.5rem] sm:w-[16rem] xs:w-[16.5rem] h-[3rem] text-[1.25rem] rounded-[0.56rem] text-white font-semibold ${
                                    agreementChecked ? 'bg-[#FF922E]' : 'bg-[#CCCCCC]'
                                }`}
                                disabled={!agreementChecked}
                                type="submit"
                            >
                              {parse(intl.formatMessage({id: "AcademyTumar.Apply"}))}
                            </button>
                          </div>
                        </div>
                      </header>
                    </Form>
                )}
              </Formik>
          )}
        </AppStateContext.Consumer>
        {showResultSuccessPopup && <ResultSuccess
            isOpen={showResultSuccessPopup}
            onClose={() => setShowResultSuccessPopup(false)}
            text="Popup.ApplicationSuccess.SuccessText"
            closeForm={() => closeModalHandler()}
        />}
        {showSubmitErrorModal && <ResultError
            isOpen={showSubmitErrorModal}
            onClose={() => setShowSubmitErrorModal(false)}
            text="Popup.ApplicationError.ErrorText"
            closeForm= {() => closeModalHandler()}
        />}
      </>
  );
};

export default injectIntl(AcademyTumarForm);